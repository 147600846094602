@import '../../styles/variables';
@import '../../styles/mixins';

@media screen and (max-width: 580px) {
  
  .col {
    overflow-x: scroll;
  
    .card {
      background-color: $input-background;
      padding: 30px 80px 0px 70px;
      border-radius: 20px;
      height: 100%;
      box-shadow: 0px 0px 20px 4px rgba($color: $light-purple, $alpha: 1.0);
      min-width: 900px;
      margin-bottom: 12px;
     .algn{
      text-align: center;
      span{
        color:$blue;
      }
     }
      @include media-breakpoint-range (tab-up) {
        margin-top: 25px;
      }
      .row{
        background-color: $dark-purple;  
        border-radius: 15px;
        padding: 3px;
        margin-top: 5px;
        .action{
          padding: 4px 1px;
          .mt30{
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
            float: right;
          }
        }
      }
      .flex13{
          color:$blue;
      }
    }
    }
}
.col { 
.card {
  background-color: $input-background;
  padding: 30px 80px 0px 70px;
  border-radius: 20px;
  height: 100%;
  box-shadow: 0px 0px 20px 4px rgba($color: $light-purple, $alpha: 1.0);
  min-width: 900px;
  margin-bottom: 12px;
  .algn{
    text-align: center;
    span{
      color:$blue;
    }
   }
  .aln_r{
    text-align: end;
    span{
      color:$blue;
    }
  }
  @include media-breakpoint-range (tab-up) {
    margin-top: 25px;
  }
  .row{
    background-color: $dark-purple;  
    border-radius: 15px;
    padding: 3px;
    margin-top: 5px;
    .action{
      padding: 4px 1px;
      .mt30{
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
        float: right;
      }
    }
  }
  .flex13{
      color:$blue;
  }
}
}