@import '../../styles/variables';
@import '../../styles/mixins';

.card {
  padding: 30px 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 40px 5px rgba($color: $glowing-button, $alpha: 1.0);
  background: rgba(53, 176, 230, 1);
  margin-top: 30px;
  background: -moz-linear-gradient(
    45deg,
    rgba(53, 176, 230, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(218, 7, 162, 1) 100%
  );
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0%, rgba(53, 176, 230, 1)),
    color-stop(50%, rgba(93, 68, 165, 1)),
    color-stop(100%, rgba(218, 7, 162, 1))
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(53, 176, 230, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(218, 7, 162, 1) 100%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(53, 176, 230, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(218, 7, 162, 1) 100%
  );
  background: -ms-linear-gradient(
    45deg,
    rgba(53, 176, 230, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(218, 7, 162, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(53, 176, 230, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(218, 7, 162, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35b0e6', endColorstr='#da07a2', GradientType=1 );
  @include media-breakpoint-range (tab-up) {
    margin-top: 60px;
  }
  @include media-breakpoint-range (tab-landscape-up) {
    padding: 60px 50px;
    margin-top: 0px;
  }

  h3 {
    font-size: 20px;
    letter-spacing: 10px;
    border-bottom: 2px solid $border-grey;
    padding-bottom: 25px;
    margin-bottom: 40px;
  }

  .fact {
    font-size: 11px;
    letter-spacing: 1px;
    padding-bottom: 7.5px;
    margin-bottom: 7.5px;
    border-bottom: 2px solid $border-grey;

    .label {
      padding-left: 10px;
    }

    .value {
      word-break: break-all;
    }
  }

}
