@import "../../styles/variables";
@import "../../styles/mixins";

.navigation {
    display: flex;
     margin-bottom: 30px;
   
     button {
    //    flex-grow: 1;
       background-color: $tab-button;
       height: 60px;
       color: white;
       border: none;
       transition: all 0.36s ease;
       border-radius: 50px;
       width: 200px;
       font-size: 18px;
       
       @include media-breakpoint-range (tab-up) {
        margin: 0 -15px;
       }
   
       &.active {
         box-shadow: 0px 0px 20px 4px rgba($color: $glowing-purple, $alpha: 1);
         background: rgba(195,12,159,1);
         background: -moz-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(195,12,159,1)), color-stop(57%, rgba(89,61,161,1)), color-stop(100%, rgba(47,127,194,1)));
         background: -webkit-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         background: -o-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         background: -ms-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         background: linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c30c9f', endColorstr='#2f7fc2', GradientType=1 );
         z-index: 1;
       }
     }



     .timer {
      //    flex-grow: 1;
         background-color: $tab-button;
        //  height: 60px;
        padding:20px;
         color: white;
         display: flex;
         border: none;
         padding-left: 5px;
         text-align: center;
         padding-right: 5px;
         transition: all 0.36s ease;
         border-radius: 10px;
         width: auto;
         font-size: 18px;
         box-shadow: 0px 0px 20px 4px rgba($color: $glowing-purple, $alpha: 1);
         background: rgba(195,12,159,1);
         background: -moz-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(195,12,159,1)), color-stop(57%, rgba(89,61,161,1)), color-stop(100%, rgba(47,127,194,1)));
         background: -webkit-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         background: -o-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         background: -ms-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         background: linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c30c9f', endColorstr='#2f7fc2', GradientType=1 );
         z-index: 1;
         
         @include media-breakpoint-range (tab-up) {
          margin: 0 -15px;
         }
     
         float:right;
       }
   }
   @media screen and (max-width: 580px) {
    .navigation{display: block;
    .timer{float: none;
      margin-top: 40px;}
    }
   }