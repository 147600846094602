@import "../../styles/variables";
@import "../../styles/mixins";

.market {
  background-color: $dark-purple;

  h2 {
    margin-bottom: 50px;
    @include media-breakpoint-range (tab-up) {
      margin-bottom: 100px;
    }
  }

  .maker {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    padding: 0px 60px;
    
    @include media-breakpoint-range (tab-landscape-up) {
      padding: 0px 40px;
      margin-bottom: 0;
    }

    img {
      width: auto;
      max-width: 100%;
    }

    h4{
      font-family: $regular;
      font-weight: bold;
      font-size: 18px;
      text-transform: none;
      letter-spacing: 2px;
      margin-left: 30px;
    }
  }
}
