@import '../../styles/mixins';
@import '../../styles/variables';

.faq {
  @include linear-gradient(#0f0230,#161654);
  position: relative;
  padding: 0 15px;
  @include media-breakpoint-range (tab-up) {
    padding: 0;
  }

  section {
    padding-top: 100px;
  }

  .wrapper {
    position: relative;
    padding: 0px 30px 100px;
    @include media-breakpoint-range (tab-up) {
      padding: 0px 60px 100px;
    }

    h3 {
      z-index: 1;
      position: relative;
      display: block;
      width: 50%;
      margin: -40px auto 0;
      font-size: 42px;
      text-shadow: 0px 0px 15px rgba($color: $glowing-purple, $alpha: 1);
      text-align: center;
      @include linear-gradient(#120b41,#130d44);
      padding: 20px;
      margin-bottom: 40px;
      @include media-breakpoint-range (tab-landscape-up) {
        margin-bottom: 80px;
        width: 15%;
      }

    }

    &::after, &::before {
      content: '';
      display: block;
      position: absolute;
    }

    &::after {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include linear-gradient(#0f0230,#161654);
    }
  
    &::before {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(218,7,162,1);
      background: -moz-linear-gradient(left, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(218,7,162,1)), color-stop(50%, rgba(93,68,165,1)), color-stop(100%, rgba(53,176,230,1)));
      background: -webkit-linear-gradient(left, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
      background: -o-linear-gradient(left, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
      background: -ms-linear-gradient(left, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
      background: linear-gradient(to right, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#da07a2', endColorstr='#35b0e6', GradientType=1 );
      box-shadow: 0px 0px 10px -1px rgba($color: $glowing-purple, $alpha: 1);
    }

    .question {
      position: relative;
      z-index: 1;
      padding-bottom: 40px;
      position: relative;
      padding-left: 30px;
      cursor: pointer;

      &:hover {
        h4 {
          text-shadow: 0px 0px 15px rgba($color: $glowing-purple, $alpha: 1);
        }
      }

      &.open {
        &::before {
          opacity: 1;
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 24px;
        height: calc(100% - 40px);
        left: -5px;
        top: 18px;
        border: 1px solid rgba($color: #ffffff, $alpha: .75);
        border-right-color: transparent;
        opacity: 0;
        transition: opacity 0.36s ease;
      }

      h4 {
        font-size: 30px;
        letter-spacing: 2px;
        line-height: 36px;
        text-transform: none;
        margin-bottom: 25px;
        transition: text-shadow 0.2s ease;
      }

      p {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1.5px;
      }
    }
  }

}