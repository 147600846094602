@import '../../styles/variables';
@import '../../styles/mixins';

.details {
  @include linear-gradient(#161654, #0f0230);
}

.panel {
  padding: 0 20px;
  @include media-breakpoint-range (tab-up) {
    padding: 0 5%;
  }
}