@import "../../styles/variables";
@import "../../styles/mixins";

.card {
  width: 100%;
  max-width: 250px;
  margin: 60px auto;
  padding: 40px 30px 5px;
  border-radius: 25px;
  background: rgba(180, 7, 162, 1);
  background: -moz-linear-gradient(
    left,
    rgba(180, 7, 162, 1) 0%,
    rgba(60, 59, 151, 1) 50%,
    rgba(48, 149, 210, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(180, 16, 156, 1)),
    color-stop(50%, rgba(60, 59, 151, 1)),
    color-stop(100%, rgba(48, 149, 210, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(180, 16, 156, 1) 0%,
    rgba(60, 59, 151, 1) 50%,
    rgba(48, 149, 210, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(180, 16, 156, 1) 0%,
    rgba(60, 59, 151, 1) 50%,
    rgba(48, 149, 210, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(180, 16, 156, 1) 0%,
    rgba(60, 59, 151, 1) 50%,
    rgba(48, 149, 210, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(180, 16, 156, 1) 0%,
    rgba(60, 59, 151, 1) 50%,
    rgba(48, 149, 210, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#da07a2', endColorstr='#35b0e6', GradientType=1 );
  box-shadow: 0px 0px 45px -10px rgba($color: $glowing-purple, $alpha: 1.0);
  transition: all 0.2s ease;

  .title {
    color: $dark-purple;
    font-family: $bold;
    font-size: 45px;
    margin-bottom: 12px;
  }
  a{
    color: #fff;
    &:hover{
      color: #fff;
      text-decoration: none;
    }
  }

  .subtitle {
    margin-top: 15px;
    font-size: 30px;
    font-family: $regular;
    font-weight: bold;
    letter-spacing: 10px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      text-shadow: 0px 3px 6px rgba($color: #000000, $alpha: .5);
      transform: translateY(-2px);
    }
  }

  .wrapper {
    margin-left: -30px;
    margin-right: -30px;
    @include linear-gradient(#9b196c, $dark-purple);
    padding: 0px 10px;

    & > div {
      padding-top: 30px;
      margin-bottom: 10px;
    }

    .label {
      font-size: 12px;
      font-family: $regular;
      text-transform: capitalize;
      letter-spacing: 1px;
      line-height: 21px;
      margin-bottom: 0;
    }

    .value {
      font-size: 55px;
      letter-spacing: 1px;
      line-height: 55px;
      margin-bottom: 15px;
    }

    .check {
      margin:auto;
      margin-top: 5px;
      margin-bottom: 30px;
      display: block;
    }
  }
}
