@import '../../styles/variables';

.wrapper {
  background: rgba(218,7,162,1);
  background: -moz-linear-gradient(left, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(218,7,162,1)), color-stop(50%, rgba(93,68,165,1)), color-stop(100%, rgba(53,176,230,1)));
  background: -webkit-linear-gradient(left, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
  background: -o-linear-gradient(left, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
  background: -ms-linear-gradient(left, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
  background: linear-gradient(to right, rgba(218,7,162,1) 0%, rgba(93,68,165,1) 50%, rgba(53,176,230,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#da07a2', endColorstr='#35b0e6', GradientType=1 );
  padding: 4px;
  border-radius: 15px;

  .card {
    background-color: $dark-purple;
    border-radius: 15px;
    padding: 20px;

    .fee {
      font-size: 18px;
    }
  }
  .max{
    position: absolute;
    right: 50px;
    top: 144px;
    border-radius: 30px;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    background: linear-gradient(
45deg
, #da07a2 0%, #5d44a5 47%, #35b0e6 100%);
  box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1);
  }
  .maxContent{
      color: #ffffff;
  }

}