/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 8, 2021 */

@font-face {
  font-family: "senbold";
  src: url("./fonts/sen-bold-webfont.woff2") format("woff2"),
    url("./fonts/sen-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "senextrabold";
  src: url("./fonts/sen-extrabold-webfont.woff2") format("woff2"),
    url("./fonts/sen-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "senregular";
  src: url("./fonts/sen-regular-webfont.woff2") format("woff2"),
    url("./fonts/sen-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
