@import "../../styles/variables";
@import "../../styles/mixins";

.tiers {
  background-color: $dark-purple;

  h2 {
    margin-bottom: 50px;
    @include media-breakpoint-range (tab-up) {
      margin-bottom: 100px;
    }
  }

  p {
    font-size: 19px;
    margin-bottom: 25px;
  }

  .slider {
    margin-top: 40px;
    button {
      background-color: transparent;
      border: none;

      img {
        transition: transform 0.36s ease;
        height: 100px;
      }

      &.left {
        margin-right: -100px;
        &:hover {
          img {
            transform: translateX(-10%);
          }
        }
      }

      &.right {
        margin-left: -100px;
        &:hover {
          img {
            transform: translateX(10%);
          }
        }
      }

    }
  }
}
