@import "../../styles/variables";
@import "../../styles/mixins";

@media screen and (max-width: 580px) {
  .locker {
    @include linear-gradient(#161654, #0f0230);
  
    .logos {
      // display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0!important;
  
      img {
        height: 18px;
        width: auto;
        margin: 5px 16px!important;
      }
      div{
        display: inline-flex;
      }
      .mar{
        padding-left: 22%;
      }
    }
  
    .text {
      font-size: 30px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 60px;
  
      small {
        font-size: 75%;
        margin-right: 5px;
        line-height: 1;
      }
  
      span {
        line-height: 1;
      }
    }
  
    .row {
      position: relative;
      margin-top: 30px;
      input {
        padding-right: 130px;
      }
  
      button {
        position: absolute;
        top: 0;
        right: 8px!important;
      }
    }
  
  }
  
  .table {
    overflow-x: scroll;
    background-color: $dark-purple;
    .sec1{
      min-width: 700px;
    }
    .header {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .tr {
      background-color: $input-background;
      border-radius: 25px;
      margin-bottom: 10px;
      font-size: 15px;
    }
  }
}

.locker {
  @include linear-gradient(#161654, #0f0230);

  .logos {
    //display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 22%;

    img {
      height: 20px;
      width: auto;
      margin: 0 16px;
    }
    div{
      display: inline-flex;
    }
  }

  .text {
    font-size: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 60px;

    small {
      font-size: 75%;
      margin-right: 5px;
      line-height: 1;
    }

    span {
      line-height: 1;
    }
  }

  .row {
    position: relative;
    margin-top: 30px;
    input {
      padding-right: 130px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

}

.table {
  background-color: $dark-purple;
  .header {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .tr {
    background-color: $input-background;
    border-radius: 25px;
    margin-bottom: 10px;
    font-size: 15px;
  }
}