@import '../../styles/variables';
@import '../../styles/mixins';

.details {
  @include linear-gradient(#161654, #0f0230);
}

.panel {
  padding: 0 20px;
  @include media-breakpoint-range (tab-up) {
    padding: 0 5%;
  }
}
.mb25{margin-bottom: 25px;}
.pb{padding-bottom: 0;}



.timer {
  //    flex-grow: 1;
     background-color: $tab-button;
    //  height: 60px;
    padding:10px;
     color: white;
     display: flex;
     border: none;
     padding-left: 5px;
     text-align: center;
     padding-right: 5px;
     transition: all 0.36s ease;
     border-radius: 10px;
     width: 40%;
     margin-bottom: 5px;
     font-size: 25px;
     box-shadow: 0px 0px 20px 4px rgba($color: $glowing-purple, $alpha: 1);
     background: rgba(195,12,159,1);
     background: -moz-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
     background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(195,12,159,1)), color-stop(57%, rgba(89,61,161,1)), color-stop(100%, rgba(47,127,194,1)));
     background: -webkit-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
     background: -o-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
     background: -ms-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
     background: linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c30c9f', endColorstr='#2f7fc2', GradientType=1 );
     z-index: 1;
   
     
     @include media-breakpoint-range (tab-up) {
      margin: 0 -15px;
     }
 
   
   }
   
   @media screen and (max-width: 580px) {
     h5{font-size: 16px;}
     .timer{margin-left: 15px;
      width: 100%;
      margin-top: 15px;}
   }