@import '../../styles/variables';
@import '../../styles/mixins';

.card {
  padding: 30px 30px;
  border-radius: 20px;
  margin-top: 30px;
  box-shadow: 0px 0px 45px -10px rgba($color: $glowing-purple, $alpha: 1.0);
  background: rgba(195,12,159,1);
  background: -moz-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(195,12,159,1)), color-stop(57%, rgba(89,61,161,1)), color-stop(100%, rgba(47,127,194,1)));
  background: -webkit-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
  background: -o-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
  background: -ms-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
  background: linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c30c9f', endColorstr='#2f7fc2', GradientType=1 );
    height:calc(100% - 30px - -56px - 90px);
  
  .label {
    font-size: 14px;
    margin-bottom: 0;
  }

  .value {
    font-size: 40px;
    margin-bottom: 90px;
  }

  .fee {
    font-size: 18px;
  }
  .max{
    position: absolute;
    right: 25px;
    top: 8px;
    border-radius: 30px;
    font-size: 13px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    background: linear-gradient(
  45deg
  , #da07a2 0%, #5d44a5 47%, #35b0e6 100%);
  box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1);
  }
    .maxContent{
      color: #ffffff;
    }
}
