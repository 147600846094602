@import '../../styles/variables';
@import '../../styles/mixins';

.card {
  background-color: $dark-purple;
  padding: 30px 30px 30px 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 45px -10px rgba($color: $glowing-purple, $alpha: 1.0);

  @include media-breakpoint-range (tab-up) {
    margin-top: 40px;
  }

  .title {
    font-size: 30px;
    margin-bottom: 20px;
    font-family: $regular;
  }
  .colhd{
    color: $glowing-purple;
    font-size: 30px;
    padding-left: 20px;
  }
  .blowtxt{
    margin-top: 60px;
    color: $light-blue;
  }
  .row {
    position: relative;

    input {
      padding-right: 130px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}