@import '../../styles/variables';
@import '../../styles/mixins';
.section1 {
    padding: 0px;
    
  
  .card1{background: $tab-button;
    padding: 40px;
    border-radius: 50px;
    margin-top:3%;
    padding-left: 8%;
    padding-right: 5%;position: relative;
    .rocket{position: absolute;
        left: 75%;
        bottom: 1%;
         img{height: 430px;
            width: 300px;
        }
    }
    .astronaut{position: absolute;
        left: 67%;
        bottom: 5%;
     img{width:80px;
        height: 235px;
    }
}
.blueArc{position: absolute;
    top: 53%;
    right: 12%;
img{width: 100%;}}
.pnkshdw{width: 100%;
    height: 250px;
    position: relative;
    top: 50%;
img{width: 100%;
    height: 100%;
    position: absolute;
    top: 15%;}}
    .logo{position: absolute;width: 33%;
        top: 22%;
    img{width: 100%;}
    p{margin-top:20px ;}}
}
   
.card2{background: $tab-button;
    padding: 40px;
    border-radius: 50px;
    margin-top:3%;
    padding-left: 8%;
    padding-right: 5%;
.barImg{width: 100%;
    height: 25px;}
span{font-size: 18px;
    line-height: 35px;}
.unlckBtn{width: 200px;
    margin-top: 25px;
    margin-bottom: 25px;}
.displyBlc{display: block;}
.amountfield{ display: flex; flex-wrap: wrap;
        width: 100%; margin-top: 40px;
        justify-content: space-between;position: relative;}
.txt{margin: 0px 18px 8px;}
.buttonMax{ background:$glowing-purple;
    color: #fff;
    box-shadow: none;
    border: none;
    border-radius: 20px;
    padding: 0px 19px;
    height: 37px;
    position: absolute;
    top:38px;
    right: 5px;
}
input[type=text]{border: none;position: relative;}
.rytCol{width: 100%;
    display: flex;
    padding-left: 16%;
    padding-right: 4%;}
.leftTxt{    font-size: 18px;
    color: $light-blue;
    text-align: left;
    width: 50%;}
.rytTxt{font-size: 18px;
    text-align: right;
    width: 50%;}
.ViewBtn{color: #fff;
    border-radius: 50px;
    padding: 5px 25px;
    margin: 10px 0;
    cursor: pointer;
    background: none;
    font-size: 18px;
    box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1);}
.introTxt{font-size: 18px;
    text-align: justify;
    color: $light-blue;
    margin-top: 8px;
}

}
.section2{padding: 2% 0;
    width: 100%;
    background: #0f0230;
    margin-bottom: 0;margin-top:6%;
.card3{
    background: #161553;
    padding: 40px;
    border-radius: 50px;
    margin-top: 3%;
    padding-left: 8%;
    padding-right: 5%;
    position: relative;
    .blueShd{position: absolute;
        width: 100%;
        top: 42%;
        img{
            // width: 50%;
        height: 200px;
    }
    }
    
.helmet{
    position: relative;
    left: 40%;
    img{width: 150px;}
}
.pnkShd{
    position: absolute;
    left: 40%;
    img{height: 200px;}
    }

}
.card4{margin-top: 5%;
h2{text-transform: capitalize;margin-bottom: 6%;}
span{font-size: 20px;
    color: $light-blue;;}
p{font-size: 20px;
    padding-left: 20px;padding-right: 20%;}
}
}
}
@media screen and (max-width: 580px) {
    .section1 {
        padding: 0px;
        
      
      .card1{background: $tab-button;
        padding: 40px;
        border-radius: 50px;
        margin-top:3%;
        padding-left: 8%;
        padding-right: 5%;position: relative;
        .rocket{position: absolute;
          left: 44%;
    bottom: 1%;
             img{height: 350px;
                width: 220px;
            }
        }
        .astronaut{position: absolute;
            left: 32%;
            bottom: 5%;
         img{width:50px;
            height: 180px;
        }
    }
    .blueArc{position: absolute;
        top: 82%;
        right: 20%;
    img{width: 100%;}}
    .pnkshdw{width: 100%;
        height: 250px;
        position: relative;
        top: 50%;
    img{width: 100%;
        height: 100%;
        position: absolute;
        top: 15%;}}
        .logo{position: absolute;width: 45%;
            top:12%;
        img{width: 100%;}
        p{margin-top:5px ;}}
    }
       
    .card2{background: $tab-button;
        padding: 40px;
        border-radius: 50px;
        margin-top:3%;
        padding-left: 8%;
        padding-right: 5%;
    .barImg{width: 100%;
        height: 10px;}
    span{font-size: 18px;
        line-height: 35px;}
    .unlckBtn{width: 200px;
        margin-top: 25px;
        margin-bottom: 25px;}
    .displyBlc{display: block;}
    .amountfield{ display: flex; flex-wrap: wrap;
            width: 100%; margin-top: 40px;
            justify-content: space-between;position: relative;}
    .txt{margin: 0px 0px 8px;}
    .buttonMax{ background:$glowing-purple;
        color: #fff;
        box-shadow: none;
        border: none;
        border-radius: 20px;
        padding: 0px 19px;
        height: 37px;
        position: absolute;
        top:38px;
        right: 5px;
    }
    input[type=text]{border: none;position: relative;}
    .rytCol{width: 100%;
        display: flex;
        padding-left: 4%;
        padding-right: 6%;}
    .leftTxt{font-size: 18px;
        color: $light-blue;
        text-align: left;
        width: 50%;}
    .rytTxt{font-size: 18px;
        text-align: right;
        width: 50%;}
    .ViewBtn{color: #fff;
        border-radius: 50px;
        padding: 5px 25px;
        margin: 10px 0;
        cursor: pointer;
        background: none;
        font-size: 18px;
        box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1);}
    .introTxt{font-size: 18px;
        text-align: justify;
        color: $light-blue;
        margin-top: 8px;
    }
  
    }
    .section2{padding: 2% 0;
        width: 100%;
        background: #0f0230;
        margin-bottom: 0;margin-top:6%;
    .card3{
        background: #161553;
        padding: 40px;
        border-radius: 50px;
        margin-top: 3%;
        padding-left: 8%;
        padding-right: 5%;
        position: relative;
        .blueShd{position: absolute;
            width: 100%;
            top: 42%;
            img{
                // width: 50%;
            height: 200px;
        }
        }
        
    .helmet{
        position: relative;
        left:28%;
        img{width: 150px;}
    }
    .pnkShd{
        position: absolute;
        left: 40%;
        img{height: 200px;}
        }
    
    }
    .card4{margin-top: 5%;
    h2{text-transform: capitalize;margin-bottom: 6%;}
    span{font-size: 20px;
        color: $light-blue;;}
    p{font-size: 20px;
        padding-left: 20px;padding-right:0;}
   .unlckBtn{margin-left: 3%;
            margin-bottom: 5%;}
    }
    }
    }
}


