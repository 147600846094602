@import '../../styles/variables';
@import '../../styles/mixins';

.col{
  margin: 20px 0px;
.card {
  background-color: $input-background;
  padding: 30px 30px 0px 30px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 20px;
  margin-bottom: 16px;
  height: 100%;
  box-shadow: 0px 0px 20px 4px rgba($color: $light-purple, $alpha: 1.0);

  @include media-breakpoint-range (tab-up) {
    margin-top: 40px;
  }
  .row{
    background-color: $dark-purple;  
    border-radius: 15px;
    padding: 3px;
    margin-top: 5px;
  }
  .flex13{
      color:$blue;
  }
}
}