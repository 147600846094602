@import '../../styles/variables';
@import '../../styles/mixins';

.rewards {
  @include linear-gradient(#161654, #0f0230);

  .title {
    font-family: $regular;
    letter-spacing: 2px;
    font-size: 34px;
    line-height: 46px;
    text-transform: capitalize;
  }

  .helper {
    font-size: 16px;
    font-family: $regular;
    letter-spacing: 2px;
    line-height: 26px;
    text-transform: capitalize;
  }
  .total {
    font-size: 50px;

    sup {
      font-size: 50%;
    }
  }

}
.content{
  background: linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
  padding: 6px 14px;
  .txtAgn{
    height: 40px;
  }
  .btnAlgn{
   float: right;
   padding-right: 15px;
 }
}
